html,
body {
  margin: 0;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.728vw;
  line-height: 1.2;
  background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

code {
  font-family: "Archivo", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  padding: 0.5rem;
  margin: 0;
  border: none;
}

ion-content {
  &::part(scroll) {
    overscroll-behavior: none;
  }
}

.app {
  position: relative;
  display: flex;
  width: 100dvw;
  height: 100dvh;
  background: grey;
  overscroll-behavior: none;

  .leftSide {
    position: relative;
    display: flex;
    width: 50%;
    height: 100%;
    background-image: url("../public/left-image.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.3s linear;

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  .main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    flex: 1 1 auto;
    background: radial-gradient(218.98% 122.45% at 0% 0%, #2e386c 0%, #14142b 35.42%, #14142b 74.94%, #2e386c 100%);

    .homepage {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      gap: 30px;

      img {
        width: 40%;
        object-fit: cover;
      }

      h2 {
        color: white;
        font-size: 48px;
        font-weight: 700;

        @media screen and (max-width: 600px) {
          font-size: 32px;
        }
      }
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100dvh - 50px);
      padding: 30px;
      box-sizing: border-box;
      color: white;
      font-size: 16px;
      overflow: auto;

      @media screen and (max-width: 600px) {
        font-size: 12px;
      }

      a {
        color: #ff8633;
      }

      table {
        @media screen and (max-width: 600px) {
          font-size: 12px;
        }

        @media screen and (max-width: 500px) {
          font-size: 8px;
        }

        td {
          border: 1px solid white;
        }

        thead td {
          background: rgb(141, 137, 137);
        }
      }
    }

    .footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;
      padding: 15px 50px;
      color: white;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;

      @media screen and (max-width: 600px) {
        font-size: 12px;
      }

      a {
        color: #ff8633;
      }
    }
  }
}
